//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-888:_496,_3464,_8520,_6768,_3200,_7084,_4900,_3500;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-888')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-888', "_496,_3464,_8520,_6768,_3200,_7084,_4900,_3500");
        }
      }catch (ex) {
        console.error(ex);
      }